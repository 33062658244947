import './assets/vendor/fontawesome-free/css/all.min.css'
import './assets/css/sb-admin.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';



import App from './App';
import * as serviceWorker from './serviceWorker';
import API from './axios/axiosApi';




API.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
API.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
API.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


