import React, { useCallback, useState } from 'react';
import Header from "../elements/header";
import Footer from '../elements/footer';
import API from '../axios/axiosApi';

const GetHelp = () => {
    const initialForm = {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        issue: ''
    }
    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState([]);
    const [showThanks, setShowThanks] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
    }


    const handleSubmitSearch = useCallback(async (e) => {
        const getHelp = async () => {
            try {
                const { data: { message } } = await API.post('/support', form, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                if (message) {
                    setShowThanks(true);
                }
            } catch (error) {
                console.log(error);
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isLoggedIn');
                    localStorage.removeItem('email');
                    window.location.replace('/')
                }
            }
        }


        const validateForm = (form) => {
            const errors = [];
            for (const field of Object.keys(form))
                if (!form[field]) errors.push(`"${field}" is Mandatory`)
            setErrors(errors);
            return !errors.length
        }

        e.preventDefault();
        if (validateForm(form))
            await getHelp();
    }, [form])


    return <div>
        <Header />
        <div id="wrapper">
            <div id="content-wrapper">
                <section className="product-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading-content">
                                    <h1>Get Help: </h1>
                                    <p>Please submit your request to get help from support team</p>
                                </div>
                            </div>
                        </div>

                        {showThanks ? <div>
                            <h2>Thank you!</h2>
                            <p>Your inquiry has been submitted. Our team will contact you regarding the next steps once we have investigated the issue</p>
                        </div> : <div className="row">
                            {errors.length ? <div className="alert alert-danger">
                                <ul>{errors.map(e => <li key={e}>{e}</li>)}</ul>
                            </div> : null}
                            <div className="profile-product">
                                <div className="search-bar" style={{ marginBottom: 20, maxWidth: 500 }}>
                                    <form className="form my-10 my-lg-0" onSubmit={handleSubmitSearch}>
                                        <input className="form-control my-2 mr-sm-2" type="text" placeholder="First Name *" name="firstName" value={form['firstName']} onChange={handleInputChange} />
                                        <input className="form-control my-2 mr-sm-2" type="text" placeholder="Last Name *" name="lastName" value={form['lastName']} onChange={handleInputChange} />
                                        <input className="form-control my-2 mr-sm-2" type="tel" placeholder="Mobile *" name="mobile" value={form['mobile']} onChange={handleInputChange} />
                                        <input className="form-control my-2 mr-sm-2" type="email" placeholder="E-mail *" name="email" value={form['email']} onChange={handleInputChange} />
                                        <textarea className="form-control my-2 mr-sm-2" rows={5} placeholder="Issue *" name="issue" value={form['issue']} onChange={handleInputChange} />
                                        <button className="btn btn-primary my-2 my-sm-0" >Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>}

                    </div>
                </section>
            </div>
        </div >
        <Footer />
    </div >
}

export default GetHelp;