import React, { useState } from "react";
import { Redirect, useLocation } from 'react-router-dom'

export default function Google() {

    const [isLoading, setIsLoading] = useState(true);
    const query = useLocation();
    const url = new URLSearchParams(query.search);

    if (url.has('token') && url.has('email') && isLoading) {
        localStorage.setItem('token', url.get('token'));
        localStorage.setItem('email', url.get('email'));
        setIsLoading(false)
    }

    return (isLoading ? (<div className="d-flex justify-content-center align-items-center vh-100">
        < h3 > Loading...</h3 >
    </div >) : <Redirect to="/search" />
    );
}