import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from "./pages/login";
// import Dashboard from "./pages/dashboard";
import Product from "./pages/product";
import Search from "./pages/search";
import Google from "./pages/google";
import History from "./pages/history";
import Signup from './pages/signup';
import Users from './pages/users';
import GetHelp from './pages/get-help';
import './App.css';





class App extends Component {

    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/signup' component={Signup} />
                        {/* <Route path='/dashboard' component={Dashboard} /> */}
                        <Route path="/history" component={History} />
                        <Route path='/search' component={Search} />
                        <Route path='/product' component={Product} />
                        <Route path='/google' component={Google} />
                        <Route path='/users' component={Users} />
                        <Route path='/support' component={GetHelp} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
