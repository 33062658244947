import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from "../elements/header";
import Footer from '../elements/footer';
import API from '../axios/axiosApi';
import moment from 'moment';
import produce from 'immer';
import { useHistory } from "react-router-dom";

const Users = () => {

    const [user, setUser] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [users, setUsers] = useState([]);
    const [userName, setUserName] = useState(null);

    const { push } = useHistory();

    const isAdmin = useMemo(() => user?.role === 'ADMIN', [user]);

    const checkProfile = async () => {
        try {
            const { data } = await API.get('auth/profile', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            console.log('data', data);
            if (data.role !== 'ADMIN') {
                // window.location.replace('/search')
                push('/search');
            } else {
                var emailId = data.email
                localStorage.setItem('email', emailId);
                setUser(data);
                const username = data?.username || data?.firstName || data.email?.split('@')[0];
                setUserName(username);
            }
        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('email');
                window.location.replace('/')
            }
        }
    }

    const getUsersList = async (email) => {
        try {
            setIsSpinning(false);
            const url = email ? `/v1/users?email=${email}` : '/v1/users'
            const { data } = await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            setUsers(data);
            setIsSpinning(true);
        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('email');
                window.location.replace('/')
            }
        }
    }

    const handleSearchInputChange = (e) => {
        const { value } = e.target;
        setSearchInput(value);
    }

    const clearSearch = async (e) => {
        setSearchInput('');
        await getUsersList();
    }

    const handleSubmitSearch = useCallback(async (e) => {
        e.preventDefault();
        await getUsersList(searchInput);
    }, [searchInput])

    const handleActivateUser = async (user) => {
        console.log('@user', user);
        try {
            await updateUser(user.id, 'isActivated', !user.isActivated);
        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('email');
                window.location.replace('/')
            }
        }
    }

    const updateUser = async (userId, key, value) => {
        try {
            await API.patch(`/v1/users/${userId}`, { [key]: value }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            setUsers(prev => produce(prev, draft => {
                const currentUserIndex = draft.findIndex(u => u.id === userId);
                draft[currentUserIndex][key] = value;
            }));
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        checkProfile();
        getUsersList();
    }, [])

    useEffect(() => {
        console.log('@@', searchInput);
    }, [searchInput])

    return <div>
        <Header loading={isSpinning} userName={userName} isAdmin={isAdmin} />
        <div id="wrapper">
            <div id="content-wrapper">
                <section className="product-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading-content">
                                    <h1>Users: </h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="profile-product">
                                <div className="search-bar" style={{ marginBottom: 20 }}>
                                    <form className="form-inline my-10 my-lg-0" onSubmit={handleSubmitSearch}>
                                        <input className="form-control mr-sm-2" type="search" placeholder="Search user..." aria-label="Search" value={searchInput} onChange={handleSearchInputChange} />
                                        <button className="btn btn-primary my-2 my-sm-0" >Search</button>
                                        <button className="btn btn-outline-primary my-2 my-sm-0 ml-2" onClick={clearSearch}>Clear</button>
                                    </form>
                                </div>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Registered At</th>
                                            <th>Role</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isSpinning === false ? <img alt="loader" src="/ajax-loader.gif" /> : users.map((user, index) => <tr key={user.id}>
                                            <td>{index + 1}</td>
                                            <td>{`${user.email}`}</td>
                                            <td>{moment(user.created_at).format('YYYY-MM-DD')}</td>
                                            <td>{user.role}</td>
                                            <td>
                                                <button className={`btn ${!user.isActivated ? 'btn-primary' : 'btn-danger'}`} style={{ minWidth: 110 }} onClick={() => handleActivateUser(user)}>{user.isActivated ? 'Deactivate' : 'Activate'}</button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div >
        <Footer />
    </div >
}

export default Users;