import React, { useEffect, useMemo, useState } from 'react';
import Header from "../elements/header";
import Footer from '../elements/footer';
import API from '../axios/axiosApi';
import moment from 'moment';

const History = () => {

    const [user, setUser] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchHistory, setSearchHistory] = useState([]);
    const [userName, setUserName] = useState(null);

    const isAdmin = useMemo(() => user?.role === 'ADMIN', [user]);

    const checkProfile = async () => {
        try {
            const { data } = await API.get('auth/profile', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            console.log('data', data);
            var emailId = data.email
            localStorage.setItem('email', emailId);
            setUser(data);
            setUserName(data?.username || data?.firstName || data.email?.split('@')[0]);
            setIsSpinning(true);
        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('email');
                window.location.replace('/')
            }
            setIsSpinning(true);
        }
    }

    const getSearchHistory = async () => {
        try {
            const { data: { data } } = await API.get('results', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            console.log('data', data);
            setSearchHistory(data);
        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('email');
                window.location.replace('/')
            }
            setIsLoading(true);
        }
    }

    useEffect(() => {
        checkProfile();
        getSearchHistory();
    }, [])

    const layout = useMemo(() => {
        return <section className="product-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-content">
                            <h1>Search History: </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="profile-product">
                        {isLoading === true ?
                            <div className="loader-bg">
                                <div className="loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className="loder-text">
                                    <p>Sit tight, this takes about <span className="punk-text">5 minutes.</span> <br />Go and grab a <span className="sky-blue-text">coffee.</span> </p>
                                    <button className="btn btn-primary my-2 my-sm-0" onClick={() => this.cancelScrapping()}>Cancel</button>
                                </div>
                            </div> :
                            <ol>
                                {searchHistory.filter(s => s.isCompleted).map((value) => <li key={value.uuid}><a href={`/product?uuid=${value.uuid}&keyword=${value.keyword}`}>{`${value.keyword} (${moment(value.created_at).format('YYYY-MM-DD')})`}</a></li>)}
                            </ol>
                        }
                    </div>
                </div>
            </div>
        </section>
    }, [searchHistory, isLoading])

    return <div>
        <Header loading={isSpinning} userName={userName} isAdmin={isAdmin} />
        <div id="wrapper">
            <div id="content-wrapper">
                {/* {layout} */}
                {isSpinning === false ? <img alt="loader" src="/ajax-loader.gif" /> : layout}
            </div>
        </div>
        <Footer />
    </div>
}

export default History;