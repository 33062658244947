import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
class Footer extends React.Component
{
  render()
  {
    return (
        <footer className="footer-content">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<ul>
						<li><a href="#/">© 2022 Sourci - Australia’s leading sourcing solution company. All Rights Reserved</a></li>
						<li><a href="#/">Privacy Policy</a></li>
						<li><a href="#/">HQ: Level 5/600 Church Street, Cremorne VIC 3121 Australia</a></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
    )
  }
}
export default Footer;