import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
// import TitleComponent from "../pages/title";
import '../App.css';


export default class Header extends Component {

    state = {
        toDashboard: false,
    };


    handleClickLogout = (e) => {
        e.preventDefault();
        console.log("ENTERED")
        sessionStorage.removeItem('allProperties');
        localStorage.removeItem('token');
        localStorage.removeItem("email");
        localStorage.setItem('isLoggedIn', false);
        this.setState({ toDashboard: true });
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <header className="black-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="navbar-brand" href="/search"><img src="/logo.png" alt="logo" /></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                {this.props.userName && <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#/"> {this.props.loading === true && this.props.userName?.charAt(0).toUpperCase() + this.props.userName?.slice(1) ? this.props.userName?.charAt(0).toUpperCase() + this.props.userName?.slice(1) : null} <img src="/user.png" alt="user" /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/history" style={{ color: 'red' }}>History</a>
                                        </li>
                                        {this.props.isAdmin ? <li className="nav-item">
                                            <a className="nav-link" href="/users"> <img src="/settings.png" alt="setting" />Administration</a>
                                        </li> : null}
                                        <li className="nav-item">
                                            <a className="nav-link" href="#/" onClick={this.handleClickLogout}> <img src="/power.png" alt="power" />Logout</a>
                                        </li>
                                    </ul>
                                </div>}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
