import React, { Component } from 'react'
import Header from "../elements/header";
import Footer from '../elements/footer';
import { Redirect } from 'react-router-dom';
import API from '../axios/axiosApi';

export default class search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productItem: '',
            properties: [],
            toProduct: false,
            setLoader: false,
            userName: "",
            userRole: '',
            isSpinning: false,
            errMsg: "",
            scrapeUUID: '',
        };
    }

    componentDidMount() {
        API.get('auth/profile', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(({ data }) => {
                var userRole = data.role;
                var userName = data.userName || data.firstName || data.email.split('@')[0];
                var emailId = data.email
                localStorage.setItem('email', emailId);
                this.setState({ userRole, userName, isSpinning: true });
            })
            .catch(error => {
                console.log(error.response);
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isLoggedIn');
                    localStorage.removeItem('email');
                    window.location.replace('/')
                }
            });

    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    getScrapeResult() {
        if (!this.state.scrapeUUID) return;
        API.get(`result/${this.state.scrapeUUID}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }).then(response => {
            const { isCompleted, data, isCanceled, cancelReason } = response.data;
            if (isCanceled) {
                clearInterval(this.timer);
                this.setState({ errMsg: cancelReason, setLoader: false })
            }
            if (isCompleted) {
                clearInterval(this.timer);
                if (!data) {
                    this.setState({ errMsg: `Unable to find the product detail for : ${this.state.productItem}`, setLoader: false })
                } else {
                    this.setState({ properties: data, setLoader: false, toProduct: true })
                }
            }
        }).catch(error => {
            clearInterval(this.getScrapeResultTimeout);
            console.log(error)
        })
    }

    cancelScrapping() {
        clearInterval(this.timer);
        this.timer = null;
        this.setState({ setLoader: false });
    }

    handleSearchProduct = event => {
        this.setState({ productItem: event.target.value, errMsg: "" });
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log("okay");
        this.setState({ setLoader: true });
        this.setState({ errMsg: "" })
        sessionStorage.setItem('search_term', this.state.productItem);
        if (localStorage.getItem('listView')) {
            localStorage.removeItem('listView');
        }
        // this.setState({productItem: "abc"})
        API.post('scrape', {
            product: this.state.productItem,
        }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }).then((response) => {
            const { uuid } = response.data;
            this.setState({ scrapeUUID: uuid });
            this.timer = setInterval(() => this.getScrapeResult(), 10_000);
        }).catch(error => {
            console.log(error);
        })
    }

    handleSearchKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            this.setState({ productItem: e.target.value, errMsg: "" });
            this.handleSearch();
        }
    }

    render() {
        return (
            <div>
                <Header loading={this.state.isSpinning} userName={this.state.userName} isAdmin={this.state.userRole === 'ADMIN'} />

                <section className="search-content-area">
                    {
                        (() => {
                            if (this.state.setLoader === true) {
                                return (
                                    // <div><img alt="loader" src= "/ajax-loader.gif" /></div>
                                    <div>
                                        <div className="loader-bg">
                                            <div className="loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <div className="loder-text">
                                                <p>Sit tight, this takes about <span className="punk-text">5 minutes.</span> <br />Go and grab a <span className="sky-blue-text">coffee.</span> </p>
                                                <button className="btn btn-primary my-2 my-sm-0" onClick={() => this.cancelScrapping()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                )

                            } else if (this.state.toProduct === true) {
                                return (
                                    <Redirect to={{
                                        pathname: '/product',
                                        // state: {productItem: "abc", productDetail:this.state.properties},
                                        state: this.state.properties,
                                        //searchTerm: this.state.productItem
                                    }} />
                                )
                            }
                        })()
                    }
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 search-bar-area">
                                <h1>ARE YOU READY TO MAKE SOMETHING <span className="special-character">AMAZING?</span></h1>
                                <form className="form-inline my-2 my-lg-0">
                                    <input className="form-control mr-sm-2" type="search" placeholder="What are you looking for, today?" aria-label="Search" onChange={this.handleSearchProduct} onKeyPress={this.handleKeypress} />
                                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={this.handleSearch}>Search</button>
                                </form>
                                {this.state.errMsg ? <p className="errMsg">{this.state.errMsg}</p> : null}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
