import React, { Component } from 'react';
import API from '../axios/axiosApi';
import { Redirect } from 'react-router-dom';
import Footer from '../elements/footer';
import Head from "../elements/loginHeader";

export default class Signup extends Component {

    state = {
        username: '',
        email: '',
        password: '',
        redirect: false,
        authError: false,
        isLoading: false,
        location: {},
        loginStatus: '',
    };

    handleUsernameChange = event => {
        this.setState({ username: event.target.value });
    };
    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };
    handlePwdChange = event => {
        this.setState({ password: event.target.value });
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        const email = this.state.email;
        const password = this.state.password;
        const data = {
            email: email,
            password: password
        };

        API.post('auth/register', data)
            .then(({ data }) => {
                console.log('@data', data)
                if (data.user) {
                    this.setState({ loginStatus: 'User registered successfully', isLoading: false });
                    localStorage.setItem('token', data.authToken);
                    this.setState({ isLoading: false });
                    localStorage.setItem('isLoggedIn', true);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ authError: true, isLoading: false });
            });
    };

    renderRedirect = () => {
        if (localStorage.getItem('token') != null) {
            return <Redirect to='/search' />
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        return (
            <div>
                <Head />
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li><a href="#/">Having Issues? </a></li>
                                    <li><a className="web-link" href="/support">Get Help </a></li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <div className="global-container">
                                    <div className="card login-form">
                                        <div className="card-body">
                                            <h3 className="card-title">Sign up to Sourci</h3>
                                            <p className="sub-heading"> Ready to start your journey today?</p>
                                            <div className="card-text">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label htmlFor="inputUsername">Username</label>
                                                        <input className={"form-control form-control-sm " + (this.state.authError ? 'is-invalid' : '')} id="inputUsername" placeholder="Username" type="text" name="username" onChange={this.handleUsernameChange} autoFocus required />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputEmail">Email address</label>
                                                        <input className={"form-control form-control-sm " + (this.state.authError ? 'is-invalid' : '')} id="inputEmail" placeholder="Email address" type="text" name="email" onChange={this.handleEmailChange} required />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputPassword">Password</label>
                                                        <a href="#/">Forgot password?</a>
                                                        <input type="password" className={"form-control form-control-sm" + (this.state.authError ? 'is-invalid' : '')} id="inputPassword" placeholder="******" name="password" onChange={this.handlePwdChange} required />
                                                    </div>
                                                    {/* <button type="submit" class="btn btn-primary btn-block">Sign In</button> */}
                                                    <button className="btn btn-primary btn-block" type="submit" disabled={this.state.isLoading ? true : false}>Signup &nbsp;&nbsp;&nbsp;
                                                        {isLoading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </button>
                                                    <a href={`${process.env.REACT_APP_API_URL}auth/google`} className="btn btn-outline-primary" style={{ width: '100%', height: 55, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16 }}>Signup with Google</a>
                                                    <div className="sign-up">
                                                        Already have an account?&nbsp;<a href="/login">Login</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                </section>
                <Footer />
            </div>
        );
    }
}


